import { render, staticRenderFns } from "./conversation-container.vue?vue&type=template&id=a5cfe18e&scoped=true&"
import script from "./conversation-container.vue?vue&type=script&lang=js&"
export * from "./conversation-container.vue?vue&type=script&lang=js&"
import style0 from "./conversation-container.vue?vue&type=style&index=0&id=a5cfe18e&prod&lang=less&scoped=true&"
import style1 from "./conversation-container.vue?vue&type=style&index=1&id=a5cfe18e&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a5cfe18e",
  null
  
)

export default component.exports