<script>
import IsEqual from 'lodash/isEqual'
import UniqBy from 'lodash/uniqBy'
import _baseDropdownPicker from '../_base-dropdown-picker.vue'
import { getDiscoverPollerConfigApi } from '@modules/asset-management/api/discovery-agents-api'

export default {
  name: 'PollerConfigPicker',
  extends: _baseDropdownPicker,
  props: {
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    mandatory: { type: Boolean, default: false },
    showNmapPoller: { type: Boolean, default: false },
  },
  data() {
    this.unassignedValue = null
    return {
      archivedOptions: [],
      list: [],
    }
  },
  computed: {
    options() {
      const options = this.list
      return UniqBy(options.concat(this.archivedOptions), 'key')
    },
  },
  watch: {
    options: {
      immediate: true,
      handler(newValue, prevValue) {
        if (this.value) {
          const selectedValues = Array.isArray(this.value)
            ? this.value
            : [this.value]
          const options = newValue
          const availableOptions = this.$attrs['additional-options']
            ? (this.$attrs['additional-options'] || []).concat(options)
            : options
          const allAvailableIds = availableOptions.map((option) => option.key)

          const missingItems = selectedValues.filter(
            (id) => allAvailableIds.indexOf(id) === -1
          )

          if (missingItems.length) {
            if (this.validateArchivedValue && !this.multiple) {
              this.$emit('change', this.unassignedValue)
            } else if (this.showNmapPoller) {
              this.fetchMissingItems(missingItems)
            }
          }
        }
        if (!IsEqual(newValue, prevValue)) {
          this.setDefaultValue()
        }
      },
    },
    showNmapPoller: {
      immediate: true,
      handler: 'getDiscoverPollerConfig',
    },
  },
  created() {
    this.getDiscoverPollerConfig()
  },
  methods: {
    getDiscoverPollerConfig() {
      getDiscoverPollerConfigApi({
        nmapEnabled: this.showNmapPoller,
      }).then(({ items }) => {
        return (this.list = items.map((t) => ({
          ...t,
          text: t.text || t.name,
          key: t.id,
        })))
      })
      this.setDefaultValue()
    },
    fetchMissingItems(missingItems) {
      getDiscoverPollerConfigApi({
        ids: missingItems,
      }).then(({ items }) => {
        return (this.archivedOptions = items.map((t) => ({
          ...t,
          text: t.text || t.name,
          disabled: true,
          key: t.id,
        })))
      })
    },
    setDefaultValue() {
      if (!this.value && this.mandatory) {
        let defaultValue = this.options[0]
        if (defaultValue) {
          this.$emit('change', defaultValue.key)
        }
      }
    },
  },
}
</script>
