<template>
  <div>
    <div class="flex items-center">
      <RequesterEmailSelector
        ref="requesterEmailSelectorRef"
        :search-text="searchText"
        :options="requesters"
        :disabled="disabled"
        :auto-focus="autoFocus"
        :search-field="searchField"
        v-bind="$attrs"
        @change="handleChange"
        @select="handleSelect"
        @blur="
          ($event) => {
            $emit('blur', $event)
            handleBlur(true)
          }
        "
      >
        <template v-slot:menu-item="{ item, selectItem }">
          <div
            class="dropdown-item flex items-center"
            href="javascript:;"
            @click.stop="selectItem(item)"
          >
            <FlotoAvatarName
              :avatar="item.avatar"
              :name="autoCompleteItemText(item)"
              show-tooltip
            />
          </div>
        </template>
      </RequesterEmailSelector>
      <template v-if="advancedSearchable && !disabled && !isPortalLogin">
        <div class="filter-handler">
          <UserSelectionDrawer
            type="requester"
            :filter-data="filterData"
            @submit="($event, items) => handleAddSelectedItem($event, items)"
          />
        </div>
      </template>
    </div>
    <div v-if="showHint" class="text-left" style="line-height: initial">
      <template v-if="selectedRequester">
        <div class="flex justify-start">
          <FlotoUserDrawer
            :key="selectedRequester.id"
            :user="selectedRequester"
          >
            <template v-slot="{ open }">
              <span class="text-neutral md-text-xs">
                {{ $t('requester_name') }}:
              </span>
              <a @click="open">
                {{ autoCompleteItemText(selectedRequester) }}
                <tempalte v-if="selectedRequester.removed">
                  ({{ $t('archived') }})
                </tempalte>
              </a>
            </template>
          </FlotoUserDrawer>
        </div>
      </template>
      <template v-else-if="value && showHint && !selectedRequester && isBlured">
        <small class="text-neutral">
          {{ $t('requester_will_be_created') }}
          <span
            v-if="myAllowedModules.indexOf('request.can_create_requester') >= 0"
          >
            / <UserCreateDrawer :name="value" />
          </span>
        </small>
      </template>
    </div>
  </div>
</template>

<script>
import Bus from '@utils/emitter'
import Throttle from 'lodash/throttle'
import api from '@api'
import { authComputed } from '@state/modules/auth'
import { PreferenceComputed } from '@state/modules/preference'
import RequesterEmailSelector from './option-picker/requester-email-selector'
import { searchUserByEmailApi, getUserApi } from '@modules/users/users-api'
import { transformUserForVuex } from '@data/user'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'
import UserSelectionDrawer from '@components/item-selection-list/user-selection-drawer'
import UserCreateDrawer from '@components/item-creation-forms/user-create-drawer'

export default {
  name: 'FlotoRequesterEmail',
  components: { RequesterEmailSelector, UserSelectionDrawer, UserCreateDrawer },
  model: {
    event: 'update',
  },
  props: {
    value: { type: [String, Number], default: undefined },
    size: { type: String, default: undefined },
    showHint: { type: Boolean, default: false },
    autoFocus: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    isEmail: { type: Boolean, default: false },
    filterData: {
      type: [Object, Array],
      default() {
        return {}
      },
    },
    // eslint-disable-next-line
    advancedSearchable: { type: Boolean, default: true },
  },
  data() {
    return {
      requesters: [],
      loading: false,
      selectedRequester: undefined,
      isBlured: false,
      searchText: '',
      isDirty: false,
    }
  },
  computed: {
    ...authComputed,
    ...PreferenceComputed,
    ...SupportPortalConfigComputed,
    valueField() {
      if (this.isEmail) {
        return 'email'
      }
      return 'id'
    },
    searchField() {
      if (this.isEmail) {
        return 'email'
      }
      return 'name'
    },
  },
  watch: {
    searchText: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.handleSearch(newValue)
        }
      },
    },
  },
  created() {
    this.handleSearch = Throttle(this.handleSearch, 650)
    this.handleBlur()
    if (this.value && isNaN(this.value)) {
      this.searchText = this.value
    }
    const handleBlurFromFormRules = () => {
      this.isDirty = false
      this.handleBlur()
    }
    Bus.$on('app:form:reqeusteremail:blur', handleBlurFromFormRules)
    const handleNewUserCreate = (value) => {
      this.isDirty = false
      this.searchText = value.name
      this.handleSearch(value.name)
    }
    Bus.$on('app:form:reqeusteremail:user:create', handleNewUserCreate)
  },
  methods: {
    handleAddSelectedItem(_, items) {
      this.$nextTick(() => {
        this.handleSelect((items || [])[0])
        this.$emit('blur', this.searchText)
        this.handleBlur(true)
      })
    },
    autoCompleteItemText(requester) {
      if (this.isEmail) {
        return requester.email
      }
      const suffix =
        requester.email || requester.userLogOnName
          ? `(${requester.email || requester.userLogOnName})`
          : ''

      return `${requester.name} ${suffix}`
    },
    handleChange(item) {
      this.isDirty = true
      const value =
        typeof item === 'object'
          ? item
          : { [this.valueField]: item, [this.searchField]: item }
      if (
        !value ||
        (this.selectedRequester &&
          `${value[this.searchField]}` !==
            `${this.selectedRequester[this.searchField]}`)
      ) {
        this.selectedRequester = undefined
        this.isBlured = false
      }
      this.$emit('update', value[this.valueField])
      this.searchText = value[this.searchField]
    },
    handleSelect(item) {
      if (item) {
        this.selectedRequester = item
        this.isBlured = false
        this.$emit('update', item[this.valueField])
        this.$emit('requesterSelected', {
          ...item,
          [this.valueField]: item[this.valueField],
          [this.searchField]: item[this.searchField],
        })
        this.searchText = item[this.searchField]
        this.$nextTick(() => {
          this.handleBlur()
        })
      }
    },
    handleSearch(value) {
      if (!this.loggedIn) {
        return
      }
      if (!value) {
        return
      }
      if ((value || '').length < 2) {
        return
      }
      this.loading = true
      this.options = []
      searchUserByEmailApi(!this.loggedIn, {}, value, this.isEmail)
        .then((response) => {
          if (response.responseSearchTerm !== value) {
            this.loading = false
            return Promise.resolve()
          }
          let data = response.items
          if (
            Object.keys(this.filterData || {}).length &&
            this.filterData.key &&
            this.filterData.value
          ) {
            data = data.filter(
              (o) => o[this.filterData.key] === this.filterData.value
            )
          }
          const r = data.filter((r) => `${r[this.searchField]}` === `${value}`)
          if (r.length === 1) {
            this.selectedRequester = r[0]
            this.$emit('requesterSelected', {
              ...r[0],
              [this.valueField]: r[0][this.valueField],
              [this.searchField]: r[0][this.searchField],
            })
            this.$refs.requesterEmailSelectorRef &&
              this.$refs.requesterEmailSelectorRef.hide()
          }
          this.requesters = data.map((i) => ({
            ...i,
            [this.valueField]: i[this.valueField],
            [this.searchField]: i[this.searchField],
          }))
        })
        .finally(() => (this.loading = false))
    },
    handleBlur(checkIsBlured) {
      if (this.isPortalLogin) {
        if (
          this.allowRequesterToCreateIncidentBehalfOfOtherRequester &&
          !this.selectedRequester
        ) {
          this.isBlured = true
          this.$emit('requesterSelected')
        }
        return
      }
      if (
        // email.validate(this.value) &&
        this.value &&
        !this.selectedRequester &&
        this.showHint
      ) {
        if (checkIsBlured) {
          this.isBlured = true
          this.$emit('requesterSelected')
        }
        if (isNaN(this.value) || (!isNaN(this.value) && this.isDirty)) {
          api
            .get(`/requester/byemail/${this.value}`, {
              notify: false,
            })
            .then((data) => {
              const user = transformUserForVuex(data)
              this.selectedRequester = {
                ...user,
                removed: data.removed || false,
                [this.valueField]: user[this.valueField],
                [this.searchField]: user[this.searchField],
              }
              this.$emit('set-default-value')
              this.searchText = user[this.searchField]
            })
            .catch(() => {
              this.$nextTick(() => {
                this.searchText =
                  this.value &&
                  (isNaN(this.value) || (!isNaN(this.value) && this.isDirty))
                    ? this.value
                    : (this.selectedRequester || {})[this.searchField]
              })
            })
        } else {
          getUserApi('requester', this.value, { notify: false }).then(
            (data) => {
              if (data) {
                const user = transformUserForVuex(data)
                this.selectedRequester = {
                  ...user,
                  removed: data.removed || false,
                  [this.valueField]: user[this.valueField],
                  [this.searchField]: user[this.searchField],
                }
                this.searchText = user[this.searchField]
                this.$emit('requesterSelected', user)
                this.$emit('blur', this.searchText)
              }
            }
          )
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
.filter-handler {
  padding: 5.5px 8px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  // color: var(--primary);
  // cursor: pointer;
}
</style>
