<template>
  <FlotoConfirmModal
    :open="showModal"
    prevent-auto-close-on-confirm
    :processing="processing"
    @hide="handleHide"
    @confirm="handleConfirmAction"
  >
    <template v-slot:icon>
      <MIcon name="info-circle" class="w-full text-primary" size="2x" />
    </template>
    <template v-slot:message>
      {{
        $t('confirm_update', {
          resource: '',
        })
      }}
    </template>
  </FlotoConfirmModal>
</template>

<script>
import Intersection from 'lodash/intersection'
import CloneDeep from 'lodash/cloneDeep'
import { CustomRulesComputed } from '@state/modules/custom-rules'
import { StatusComputed } from '@state/modules/status'

export default {
  name: 'DialogRulesConfirmModal',
  props: {
    moduleName: { type: String, required: true },
  },
  data() {
    return {
      showModal: false,
      processing: false,
    }
  },
  computed: {
    ...CustomRulesComputed,
    ...StatusComputed,
  },
  methods: {
    handleConfirmAction() {
      this.processing = true
      this.$emit('confirm')
    },
    handleHide() {
      this.showModal = false
      this.processing = false
    },
    handleShow() {
      this.showModal = true
      this.processing = false
    },
    handleCheckUpdatedFieldAvailableInDialogField(fields, change, resource) {
      let isUpdated = false
      fields.forEach((field) => {
        if (field in change && change[field] !== resource[field]) {
          isUpdated = true
          return isUpdated
        }
      })
      return isUpdated
    },
    isQualifiedFieldAvailable(fields, qualifiedFields) {
      // Filter out archived fields
      const filteredValues = fields
        .filter((field) => !field.archived)
        .map((i) => i.key)

      // Check if there are any qualified fields available
      return Intersection(filteredValues, qualifiedFields).length > 0
    },
    checkIsDialogRulesQualifide(change, fields, qualifiedFields) {
      const module =
        this.moduleName === this.$constants.SERVICE_CATALOG
          ? this.$constants.REQUEST
          : this.moduleName

      const vueXKeys = {
        statusId: `${module}Status`,
      }

      const dialogFields =
        fields || CloneDeep(this[`${this.moduleName}DialogFields`] || [])

      let qualifiedDialogFields = CloneDeep(
        this[`${this.moduleName}QualifiedDialogFields`] || {}
      )

      if (fields && qualifiedFields) {
        qualifiedDialogFields = qualifiedFields
      }

      // Convert dialog fields to strings
      const dialogFieldKeys = dialogFields.map(String)

      // Find fields that have changes
      const equalFields = Intersection(dialogFieldKeys, Object.keys(change))

      const fieldsMeetsQualificationCriteria = equalFields.every((field) => {
        // Check if the field is available in the qualified fields

        if (
          qualifiedDialogFields[field] &&
          qualifiedDialogFields[field].length
        ) {
          const isFieldAvailable =
            field in vueXKeys
              ? this.isQualifiedFieldAvailable(
                  this[vueXKeys[field]] || [],
                  qualifiedDialogFields[field]
                )
              : true

          if (isFieldAvailable) {
            return qualifiedDialogFields[field].indexOf(change[field]) >= 0
          }
          return true
        }
        return true
      })

      if (equalFields.length && fieldsMeetsQualificationCriteria) {
        return true
      }

      return false
    },
  },
}
</script>
