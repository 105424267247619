<template>
  <FlotoCrudContainer
    ref="list"
    :fetch-fn="getConversations"
    :scrollable="false"
    :create-fn="addConversation"
    :update-fn="updateConversation"
    :delete-fn="deleteConversation"
    :paging="false"
    :show-no-data="false"
    prepend-new-item
    :per-page="5"
    default-sort="createdTime"
    class="conversaton-container"
  >
    <template v-slot:add-controls="{ refreshList, sortedColumn, applySort }">
      <Component
        :is="stickySearch ? 'MAffix' : 'div'"
        :offset-top="offsetTop"
        @change="searchAffixed = $event"
      >
        <div
          v-if="!hideSearchBar"
          :class="{ 'pt-2': searchAffixed }"
          class="conversaton-container-header"
        >
          <MRow>
            <MCol v-if="searchable">
              <Searchbox
                v-model="filters"
                :available-types="filterTypeOptions"
                :sort-by="sortedColumn"
                :apply-sort="applySort"
                :apply-added-by-filter="applyAddedByFilter"
                @change="refreshList"
              />
            </MCol>
            <MCol v-if="!disabled" class="text-right" :auto-size="searchable">
              <CallBtn
                v-if="
                  moduleName === $constants.REQUEST &&
                  canAccessContactOps &&
                  contactOpsConfigContext.canCall
                "
                :resource-id="resourceId"
                :resource="resource"
              />
              <MTooltip v-if="canAddCalllog" key="calllog">
                <template v-slot:trigger>
                  <a @click="handleAddCalllog">
                    <MIcon name="phone-alt" size="lg" class="mr-4" />
                  </a>
                </template>
                <span>{{ $tc('call') }} {{ $tc('log') }}</span>
              </MTooltip>
              <MTooltip v-for="c in createOptions" :key="c.type">
                <template v-slot:trigger>
                  <a
                    :id="c.type"
                    :class="{
                      action: true,
                      [c.textClass]: true,
                      'mx-1': true,
                      [`active ${c.activeBgClass}`]:
                        c.type === (currentConversationType || {}).type,
                    }"
                    @click="setAddConversationType(c)"
                  >
                    <MIcon :name="c.icon" size="lg" />
                  </a>
                </template>
                <span>{{ c.name }}</span>
              </MTooltip>
            </MCol>
          </MRow>
          <MDivider v-if="searchable" />
        </div>
      </Component>
    </template>
    <template v-slot:form="{ submit }">
      <Transition name="slideBottom" :duration="{ leave: 0 }" mode="out-in">
        <div ref="addConversationContainerRef">
          <MRow
            v-if="currentConversationType"
            :key="currentConversationType.type"
          >
            <MCol>
              <AddBox
                :resource="resource"
                :processing="processing"
                :conversation-type="currentConversationType"
                :is-private="isAddingPrivateConversation"
                v-bind="currentConversation"
                :allow-templates="allowTemplates"
                :allow-subject="allowSubject"
                :allow-email="allowEmail"
                :allow-cc-email="allowCcEmail"
                :allow-outgoing-email="allowOutgoingEmail"
                :module-name="moduleName"
                :allow-knowledges="allowInsertknowledges"
                :rich-box-rows="richBoxRows"
                :latest-replay="latestReplay"
                :forward-defalut-content="forwardDefalutContent"
                :show-note-as-first-response="showNoteAsFirstResponse"
                :allow-save-in-draft="conversationCanSaveInDraft"
                :default-email-send-config-id="defaultOutgoingEmail.id"
                @update="updateConversation"
                @submit="($event) => submit($event)"
                @remove="currentConversationType = null"
              />
            </MCol>
          </MRow>
        </div>
      </Transition>
    </template>
    <template v-slot:before-list>
      <MRow v-if="diagnosis.text" :gutter="0">
        <MCol>
          <Conversation
            :key="diagnosis.createdAt"
            :disabled="disabled"
            type="diagnosis"
            :text="diagnosis.text"
            :created-at="diagnosis.createdAt"
            :attachments="diagnosis.fileAttachments"
            :owner="diagnosis.owner"
            :use-private-indicator="true"
            is-private
            :can-edit="true"
            :can-delete="true"
            :rich-box-rows="richBoxRows"
            @edit="editDiagnosis"
            @delete="deleteDiagnosis"
          />
        </MCol>
      </MRow>
      <MRow v-if="solution.text" :gutter="0">
        <MCol>
          <Conversation
            :key="solution.createdAt"
            :disabled="disabled"
            type="solution"
            :text="solution.text"
            :attachments="solution.fileAttachments"
            :created-at="solution.createdAt"
            :owner="solution.owner"
            :can-edit="!isPortalLogin"
            :can-delete="!isPortalLogin"
            :use-private-indicator="usePrivateIndicator"
            :rich-box-rows="richBoxRows"
            @edit="editSolution"
            @delete="deleteSolution"
          />
        </MCol>
      </MRow>
      <MRow v-if="conclusion.text" :gutter="0">
        <MCol>
          <Conversation
            :key="conclusion.createdAt"
            :disabled="disabled"
            type="conclusion"
            :text="conclusion.text"
            :attachments="conclusion.fileAttachments"
            :created-at="conclusion.createdAt"
            :owner="conclusion.owner"
            :can-edit="
              !excludedTypesForCreate ||
              excludedTypesForCreate.indexOf('conclusion') >= 0
            "
            :can-delete="
              !excludedTypesForCreate ||
              excludedTypesForCreate.indexOf('conclusion') >= 0
            "
            :use-private-indicator="usePrivateIndicator"
            :rich-box-rows="richBoxRows"
            @edit="editConclusion"
            @delete="deleteConclusion"
          />
        </MCol>
      </MRow>
    </template>
    <template v-slot:list-items="{ items, remove, update, activateItem }">
      <div
        class="dynamic-scroller-conversation"
        :style="$attrs['dynamic-scroller-style']"
      >
        <DynamicScroller
          :items="items"
          :min-item-size="54"
          :emit-update="true"
          class="scroller"
        >
          <template v-slot="{ item: conversation, index, active }">
            <DynamicScrollerItem
              :item="conversation"
              :active="active"
              :size-dependencies="[conversation.text]"
              :data-index="index"
              :data-active="active"
            >
              <Conversation
                v-if="conversation"
                :id="conversation.id"
                :key="conversation.id"
                :disabled="disabled"
                :type="conversation.type"
                :can-edit="
                  conversation.canEdit ||
                  conversation.conversationState === 'draft'
                "
                :can-delete="conversation.canDelete"
                :is-private="conversation.isPrivate"
                :text="conversation.text"
                :created-at="conversation.createdAt"
                :attachments="conversation.fileAttachments"
                :owner="conversation.owner"
                :rating="conversation.rating"
                :field-value-details="conversation.fieldValueDetails"
                :subject="conversation.subject"
                :resource="resource"
                :email-ids="conversation.emailIds"
                :cc-email-ids="conversation.ccEmailIds"
                :use-private-indicator="usePrivateIndicator"
                inline-edit
                :allow-save-in-draft="conversationCanSaveInDraft"
                :is-draft="conversation.conversationState === 'draft'"
                :update="update"
                :rich-box-rows="richBoxRows"
                :allow-templates="allowTemplates"
                :allow-knowledges="allowInsertknowledges"
                :module-name="moduleName"
                :default-email-send-config-id="defaultOutgoingEmail.id"
                style="max-height: 9999px; overflow-y: auto"
                @edit="activateItem(conversation)"
                @delete="remove(conversation)"
                @forward="handleForwardConversation"
                @reply="
                  handleForwardConversation($event, 'technician_to_requester')
                "
              />
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </div>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import {
  getDefaultOutgoingEmailApi,
  updateSolutionApi,
  updateConclusionApi,
  updateDiagnosisApi,
  getWorkApi,
  addConversationApi,
  updateConversationApi,
  deleteConversationApi,
  getConversationsApi,
} from './api'
import SortBy from 'lodash/sortBy'
import OrderBy from 'lodash/orderBy'
import { authComputed } from '@state/modules/auth'
import { PreferenceComputed } from '@state/modules/preference'
import Bus from '@utils/emitter'
import { addCallLogApi } from '@modules/contactOps-manager/contactOps-manager-api'
import { getGlobalUserApi } from '@modules/users/users-api'
import { TechnicianComputed } from '@state/modules/technician'
import Searchbox from './search-box'
import AddBox from './conversation-add-box'
import { ConversationType } from './conversation-types'
import Conversation from './conversation'
import CallBtn from './components/call-btn'
import { SupportPortalConfigComputed } from '@state/modules/support-portal-config'

export default {
  name: 'ConversationContainer',
  components: { Searchbox, AddBox, Conversation, CallBtn },
  inject: {
    contactOpsConfigContext: { config: {} },
  },
  props: {
    fetchFn: { type: Function, default: undefined },
    // eslint-disable-next-line
    searchable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    hideSearchBar: { type: Boolean, default: false },
    resourceId: { type: Number, required: true },
    resource: {
      type: Object,
      default() {
        return {}
      },
    },
    moduleName: { type: String, required: true },
    allowedTypes: {
      type: Array,
      default() {
        return []
      },
    },
    excludedTypes: {
      type: Array,
      default() {
        return ['requestor_to_technician']
      },
    },
    allowTemplates: { type: Boolean, default: false },
    allowKnowledges: { type: Boolean, default: false },
    usePrivateIndicator: { type: Boolean, default: false },
    richBoxRows: { type: Number, default: undefined },
    stickySearch: { type: Boolean, default: false },
    offsetTop: { type: Number, default: 0 },
    excludedTypesForCreate: { type: Array, default: undefined },
    ongoingCallDetails: { type: Object, default: undefined },
    allowSaveInDraft: { type: Boolean, default: false },
    // eslint-disable-next-line
    canCreate: { type: Boolean, default: true },
    canAddReview: { type: Boolean, default: false },
    applyAddedByFilter: { type: Boolean, default: false },
  },
  data() {
    this.systemUserId = -93102
    let availableTypes = SortBy(
      Object.keys(ConversationType())
        .filter(
          (k) => ConversationType()[k].modules.indexOf(this.moduleName) >= 0
        )
        .map((k) => ConversationType()[k]),
      'order'
    )
    if (this.allowedTypes.length) {
      availableTypes = availableTypes.filter((f) =>
        this.allowedTypes.includes(f.type)
      )
    }
    if (this.excludedTypes.length) {
      availableTypes = availableTypes.filter(
        (f) => !this.excludedTypes.includes(f.type)
      )
    }
    this.systemUserId = -93102
    return {
      availableTypes,
      currentConversationType: null,
      processing: false,
      solution: {},
      conclusion: {},
      diagnosis: {},
      filters: {},
      searchAffixed: false,
      latestReplay: {},
      forwardDefalutContent: {},
      showNoteAsFirstResponse: false,
      defaultOutgoingEmail: {},
    }
  },
  computed: {
    ...authComputed,
    ...PreferenceComputed,
    ...TechnicianComputed,
    ...SupportPortalConfigComputed,
    conversationCanSaveInDraft() {
      if (
        this.currentConversationType &&
        ['solution', 'conclusion', 'diagnosis', 'note'].indexOf(
          this.currentConversationType.type
        ) >= 0
      ) {
        return false
      }
      if (this.allowSaveInDraft) {
        return this.allowConversationSaveInDraft
      }
      return false
    },
    allowSubject() {
      if (
        ['forward_notification'].indexOf(
          (this.currentConversationType || {}).type
        ) >= 0
      ) {
        return true
      }
      return false
    },
    allowEmail() {
      if (
        ['forward_notification', 'technician_to_requester'].indexOf(
          (this.currentConversationType || {}).type
        ) >= 0
      ) {
        return true
      }
      return false
    },
    allowCcEmail() {
      if (
        ['forward_notification', 'technician_to_requester'].indexOf(
          (this.currentConversationType || {}).type
        ) >= 0
      ) {
        return true
      }
      return false
    },
    allowOutgoingEmail() {
      if (
        ['forward_notification', 'technician_to_requester'].indexOf(
          (this.currentConversationType || {}).type
        ) >= 0
      ) {
        return true
      }
      return false
    },
    isAddingPrivateConversation() {
      if (this.currentConversationType) {
        if (
          this.moduleName === this.$constants.REQUEST &&
          ['diagnosis', 'note', 'collaborate'].indexOf(
            (this.currentConversationType || {}).type
          ) >= 0
        ) {
          return true
        }
      }
      return false
    },
    createOptions() {
      return this.availableTypes
        .filter((t) => t.canCreate !== false)
        .filter((c) => {
          if (this.isPortalLogin) {
            if (
              [
                'requestor_to_technician',
                'comment_on_approval',
                'note',
              ].indexOf(c.type) >= 0
            ) {
              return true
            }
            if (c.type === 'review') {
              return this.canAddReview
            }
            return false
          }
          if (c.type === 'review') {
            return this.canAddReview
          }
          if (
            this.excludedTypesForCreate &&
            this.excludedTypesForCreate.indexOf(c.type) === -1
          ) {
            return false
          }
          return true
        })
    },
    filterTypeOptions() {
      return this.availableTypes.filter(
        (f) => ['solution', 'conclusion', 'diagnosis'].indexOf(f.type) === -1
      )
    },
    currentConversation() {
      if (
        this.currentConversationType &&
        this.currentConversationType.type === 'diagnosis'
      ) {
        return {
          value: this.diagnosis.text,
          fileAttachments: this.diagnosis.fileAttachments,
        }
      }
      if (
        this.currentConversationType &&
        this.currentConversationType.type === 'solution'
      ) {
        return {
          value: this.solution.text,
          fileAttachments: this.solution.fileAttachments,
        }
      }
      if (
        this.currentConversationType &&
        this.currentConversationType.type === 'conclusion'
      ) {
        return {
          value: this.conclusion.text,
          fileAttachments: this.conclusion.fileAttachments,
        }
      }
      return {}
    },
    allowInsertknowledges() {
      if (
        this.currentConversationType &&
        this.currentConversationType.type === 'solution'
      ) {
        return true
      }
      if (
        this.currentConversationType &&
        this.currentConversationType.type === 'conclusion'
      ) {
        return true
      }
      return this.allowKnowledges
    },
    canAddCalllog() {
      if (this.ongoingCallDetails) {
        return true
      }
      return false
    },
    canAccessContactOps() {
      if (this.isPortalLogin) {
        return false
      }
      const isContactOpsEnabled = this.contactOpsConfigContext.config.enabled
      const supportContactOps = this.contactOpsEnabled
      return isContactOpsEnabled && supportContactOps
    },
  },
  watch: {
    'resource.description': {
      handler(newValue, prevValue) {
        if (newValue !== prevValue) {
          this.prepaireForwardContent()
        }
      },
    },
    'resource.fileAttachments': {
      handler(newValue, prevValue) {
        if (newValue !== prevValue) {
          this.prepaireForwardContent()
        }
      },
    },
  },
  created() {
    if (this.moduleName === this.$constants.REQUEST && !this.isPortalLogin) {
      this.getDefaultOutgoingEmail()
    }
  },
  mounted() {
    const shouldShowDiagnosis = this.availableTypes.find(
      (t) => ['diagnosis', 'solution', 'conclusion'].indexOf(t.type) >= 0
    )
    if (
      shouldShowDiagnosis &&
      (this.isPortalLogin ? this.allowRequesterToAccessSolution : true)
    ) {
      getWorkApi(this.moduleName, this.resourceId, this.isPortalLogin).then(
        (data) => {
          this.solution = data.solution
          this.conclusion = data.conclusion
          this.diagnosis = data.diagnosis
          this.$emit('work-received', data)
        }
      )
    }
    const availableCommands = this.createOptions
      .filter((c) => c.command)
      .map((c) => c.command)
    const createShortcutHandler = (e, k, command) => {
      const typeToSet = this.createOptions
        .filter((c) => c.command)
        .find((c) => c.command === command)
      if (typeToSet) {
        this.currentConversationType = typeToSet
      }
    }
    Bus.$on(availableCommands, createShortcutHandler)
    this.$off('hook:beforeDestroy', () => {
      Bus.$off(availableCommands, createShortcutHandler)
    })
    this.prepaireForwardContent()
  },
  methods: {
    handleEditConvesation(activateItem, conversation) {
      this.currentConversationType = ConversationType()[conversation.type]
      this.currentConversation = conversation
      activateItem(conversation)
    },
    prepaireLatestReplay() {
      if (this.technicianDetails(this.resource.requesterId)) {
        this.latestReplay = {
          text: this.resource.description,
          createdAt: this.resource.createdAt,
          owner: `${this.technicianDetails(this.resource.requesterId).name} ${
            this.technicianDetails(this.resource.requesterId).email
              ? `(${this.technicianDetails(this.resource.requesterId).email})`
              : ''
          }`,
        }
      } else {
        this.getRequester(this.resource.requesterId).then((user) => {
          this.latestReplay = {
            text: this.resource.description,
            createdAt: this.resource.createdAt,
            owner: `${user.name} ${user.email ? `(${user.email})` : ''}`,
          }
        })
      }
    },
    prepaireForwardContent() {
      if (
        this.resource.description ||
        (this.resource.fileAttachments || []).length
      ) {
        if (this.technicianDetails(this.resource.requesterId)) {
          this.forwardDefalutContent = {
            text: this.resource.description,
            createdAt: this.resource.createdAt,
            owner: `${this.technicianDetails(this.resource.requesterId).name} ${
              this.technicianDetails(this.resource.requesterId).email
                ? `(${this.technicianDetails(this.resource.requesterId).email})`
                : ''
            }`,
            attachments: this.resource.fileAttachments,
          }
        } else {
          this.getRequester(this.resource.requesterId).then((user) => {
            this.forwardDefalutContent = {
              text: this.resource.description,
              createdAt: this.resource.createdAt,
              owner: `${user.name} ${user.email ? `(${user.email})` : ''}`,
              attachments: this.resource.fileAttachments,
            }
          })
        }
      }
    },
    handleAddCalllog() {
      const reqeustedData = {
        callType: this.ongoingCallDetails.callType,
        callTime: this.ongoingCallDetails.callStartTime,
        callFrom: this.ongoingCallDetails.requesterNumber,
      }
      return addCallLogApi(reqeustedData, this.resourceId).then(() => {
        this.refresh()
      })
    },
    technicianDetails(id) {
      const techUser = this.technician(id)
      return techUser.id ? this.technician(id) : undefined
    },
    getRequester(userId) {
      if (userId) {
        const user = this.technician(userId)
        if ((user && user.id) || userId === this.systemUserId) {
          return Promise.resolve({ name: user.name })
        } else {
          return getGlobalUserApi(userId, { archived: true }).then((data) => {
            return data
          })
        }
      }
    },
    handleForwardConversation(data, type = 'forward_notification') {
      const forward = this.createOptions.find((c) => c.type === type)
      if (forward) {
        this.setAddConversationType(forward)
        let content = {}
        if (this.technicianDetails(data.owner)) {
          content = {
            text: data.text,
            createdAt: data.createdAt,
            owner: `${(this.technicianDetails(data.owner) || {}).name} ${
              (this.technicianDetails(data.owner) || {}).email
                ? `(${this.technicianDetails(data.owner).email})`
                : ''
            }`,
            attachments: data.attachments,
          }
        } else if (data.owner === this.systemUserId) {
          content = {
            text: data.text,
            createdAt: data.createdAt,
            owner: this.$tc('system'),
            attachments: data.attachments,
          }
        } else {
          this.getRequester(data.owner).then((user) => {
            content = {
              text: data.text,
              createdAt: data.createdAt,
              owner: `${user.name} ${user.email ? `(${user.email})` : ''}`,
              attachments: data.attachments,
            }
          })
        }
        setTimeout(() => {
          if (type === 'forward_notification') {
            Bus.$emit('app:conversation:forward:content', {
              ...content,
            })
          }
          if (type === 'technician_to_requester') {
            Bus.$emit('app:conversation:reply:content', {
              ...content,
              emailIds: data.emailIds,
              ccEmailIds: data.ccEmailIds,
            })
          }
        }, 400)
      }
    },
    setAddConversationType(c) {
      if (!this.canCreate) {
        return
      }
      this.currentConversationType = c
      this.$nextTick(() => {
        const ref = this.$refs.addConversationContainerRef
        if (ref) {
          ref.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      })
    },
    getNoteAsFirstResponse(items) {
      const publisedItems = items.filter(
        (i) => i.conversationState === 'published'
      )
      const ifAnyTechnicianReplayAvailable =
        (
          publisedItems.filter((i) => i.type === 'technician_to_requester') ||
          []
        ).length > 0
      const ifAnyNoteAsFirstResponseAvailable =
        (publisedItems.filter((i) => i.noteAsFirstResponse) || []).length > 0
      this.showNoteAsFirstResponse = !(
        ifAnyTechnicianReplayAvailable || ifAnyNoteAsFirstResponseAvailable
      )
    },
    getLatestReplayFromList(items) {
      const replayTypes = this.availableTypes.filter(
        (a) =>
          ['technician_to_requester', 'requestor_to_technician'].indexOf(
            a.type
          ) >= 0
      )
      if (replayTypes.length > 0) {
        const replayList = (items || []).filter(
          (i) =>
            ['technician_to_requester', 'requestor_to_technician'].indexOf(
              i.type
            ) >= 0 && i.conversationState === 'published'
        )
        const latestReplay =
          OrderBy(replayList || [], ['createdAt'], ['desc']) || []
        if ((latestReplay || []).length) {
          const item = latestReplay[0]
          // if owner is requester then fetch owner name from id
          if (this.technicianDetails(item.owner)) {
            this.latestReplay = {
              text: item.text,
              createdAt: item.createdAt,
              owner: `${this.technicianDetails(item.owner).name} ${
                this.technicianDetails(item.owner).email
                  ? `(${this.technicianDetails(item.owner).email})`
                  : ''
              }`,
            }
          } else if (item.owner === this.systemUserId) {
            this.latestReplay = {
              text: item.text,
              createdAt: item.createdAt,
              owner: this.$tc('system'),
            }
          } else {
            this.getRequester(item.owner).then((user) => {
              this.latestReplay = {
                text: item.text,
                createdAt: item.createdAt,
                owner: `${user.name} ${user.email ? `(${user.email})` : ''}`,
              }
            })
          }
        } else if (this.resource.description) {
          this.prepaireLatestReplay()
        }
      }
    },
    getConversations(limit, offset, sortBy) {
      if (this.fetchFn) {
        return this.fetchFn(limit, offset, sortBy, this.filters).then(
          (data) => {
            if (
              (this.tenantPrefrences.populateLastConversationInReply || {})
                .value === true
            ) {
              this.getLatestReplayFromList(data.items)
            }
            this.getNoteAsFirstResponse(data.items)
            return data
          }
        )
      }
      return getConversationsApi(
        this.moduleName,
        this.resourceId,
        { ...this.filters, sortBy },
        limit,
        offset
      ).then((data) => {
        if (
          (this.tenantPrefrences.populateLastConversationInReply || {})
            .value === true
        ) {
          this.getLatestReplayFromList(data.items)
        }
        this.getNoteAsFirstResponse(data.items)
        return data
      })
    },
    addConversation(data) {
      this.processing = true
      return addConversationApi(this.moduleName, this.resourceId, {
        ...data,
        type: this.currentConversationType.type,
        name: this.currentConversationType.name,
      })
        .then((data) => {
          this.processing = false
          this.currentConversationType = null
          this.$emit('refresh')
          // @TODO kiran
          let updatedData = data
          if (Object.keys(this.filters).length && data.type) {
            if (data.type === this.filters.conversationType) {
              updatedData = data
            } else {
              updatedData = null
            }
          }
          if (
            ['technician_to_requester', 'requestor_to_technician'].indexOf(
              data.type
            ) >= 0 ||
            data.noteAsFirstResponse ||
            this.searchable
          ) {
            this.refresh()
          }
          return updatedData
        })
        .finally(() => (this.processing = false))
    },
    updateConversation(data, saveAndAddToKnowledge) {
      this.processing = true
      let updateFn, isSolution
      if (
        this.currentConversationType &&
        ['solution', 'conclusion', 'diagnosis'].indexOf(
          this.currentConversationType.type
        ) >= 0
      ) {
        if (this.currentConversationType.type === 'solution') {
          updateFn = updateSolutionApi
          if (data.text) {
            isSolution = true
          }
        }
        if (this.currentConversationType.type === 'conclusion') {
          updateFn = updateConclusionApi
        }
        if (this.currentConversationType.type === 'diagnosis') {
          updateFn = updateDiagnosisApi
        }
        if (!updateFn) {
          this.processing = false
        }
        return updateFn(this.moduleName, this.resourceId, data)
          .then((response) => {
            this.processing = false
            this.currentConversationType = null
            this.solution = response.solution
            this.conclusion = response.conclusion
            this.diagnosis = response.diagnosis
            this.$emit('work-received', response)
            this.$emit('refresh')
            if (isSolution) {
              this.$emit(
                'solution-added',
                saveAndAddToKnowledge ? data : undefined
              )
            }
          })
          .catch(() => (this.processing = false))
          .finally(() => (this.processing = false))
      } else {
        return updateConversationApi(this.moduleName, this.resourceId, data)
          .then((response) => {
            this.currentConversationType = null
            // this.$emit('refresh')
            // this.refresh()
            return response
          })
          .finally(() => (this.processing = false))
      }
    },
    editDiagnosis() {
      this.currentConversationType = ConversationType().diagnosis
    },
    editSolution() {
      this.currentConversationType = ConversationType().solution
    },
    editConclusion() {
      this.currentConversationType = ConversationType().conclusion
    },
    deleteSolution() {
      updateSolutionApi(this.moduleName, this.resourceId, {
        text: null,
        fileAttachments: [],
      })
        .then(({ solution }) => {
          this.$emit('refresh')
          return (this.solution = solution)
        })
        .finally(() => (this.processing = false))
    },
    deleteConclusion() {
      updateConclusionApi(this.moduleName, this.resourceId, {
        text: null,
        fileAttachments: [],
      })
        .then(({ conclusion }) => {
          this.$emit('refresh')
          return (this.conclusion = conclusion)
        })
        .finally(() => (this.processing = false))
    },
    deleteDiagnosis() {
      updateDiagnosisApi(this.moduleName, this.resourceId, {
        text: null,
        fileAttachments: [],
      })
        .then(({ diagnosis }) => {
          this.$emit('refresh')
          return (this.diagnosis = diagnosis)
        })
        .finally(() => (this.processing = false))
    },
    deleteConversation(data) {
      return deleteConversationApi(data).then(() => this.$emit('refresh'))
    },
    refresh() {
      this.$refs.list.refresh()
    },
    getDefaultOutgoingEmail() {
      return getDefaultOutgoingEmailApi(this.moduleName, this.resourceId).then(
        (data) => {
          this.defaultOutgoingEmail = data
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
a.action {
  width: 30px;
  height: 30px;

  &:not(.active) {
    background-color: var(--neutral-lighter-button-bg);

    &:hover {
      background-color: var(--neutral-lighter-button-hover-bg);
    }
  }

  &.active {
    color: var(--white-regular);
  }

  @apply rounded-full p-3 inline-flex items-center justify-center;
}
</style>
<style lang="less">
.dynamic-scroller-conversation {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 200px);
  overflow: hidden;
}
</style>
