<template>
  <div class="flex h-100 flex-col flex-1">
    <ListWithPreviewLayout
      ref="listwithpreviewref"
      :key="`${defaultChannel.requesterId}-${isOpen}`"
      :resource-name="$tc(moduleName, 2)"
      allow-keyboard-navigation
      :fetch-fn="getRequests"
      :update-fn="updateItem"
      :columns="tableColumns"
      :view="currentView"
      :module-name="moduleName"
      :selectable="false"
      :fetch-immediate="true"
      :hide-search-selector="true"
      :allow-split-view="false"
      @viewChange="currentView = $event"
      @navigate-to-item="navigateToItem"
    >
      <template v-slot:title>
        {{ $tc(moduleName, 2) }}
      </template>

      <template v-slot:search-selector>
        <div></div>
      </template>
      <template v-slot:search>
        <div></div>
      </template>
      <template v-slot:actions="{ refreshList }">
        <MRow v-if="!defaultChannel.unknown" :gutter="0">
          <MCol :size="2" class="text-right">
            <CreateIncidentDrawer
              v-if="!wrapUpCallData.requestId && !defaultChannel.requestId"
              :default-channel="defaultChannel"
              @refresh="refreshList"
            >
              <template v-slot:trigger>
                <MButton size="small" outline class="mr-2">
                  {{ $tc('create') }} {{ $tc('request') }}
                </MButton>
              </template>
            </CreateIncidentDrawer>
          </MCol>
        </MRow>
      </template>
      <template v-slot:grid-actions-before="{ refreshList }">
        <MTooltip>
          <template v-slot:trigger>
            <MIcon
              name="sync"
              class="mr-2 cursor-pointer text-neutral-light"
              @click="refreshList"
            />
          </template>
          {{ $t('refresh') }}
        </MTooltip>
      </template>

      <template v-slot:row="rowSlotData">
        <TableRow
          v-bind="rowSlotData"
          :module-name="moduleName"
          :closed-status-id="closedStatusId"
        />
      </template>
      <template v-slot:detail="{ item, replaceItem }">
        <Transition name="placeholder">
          <DetailView
            :use-transition="false"
            :module-name="moduleName"
            :resource-id="item.id"
            :on-change="replaceItem"
            :ongoing-call-details="defaultChannel"
          >
            <template
              v-slot:actions="{
                isTicketClosed,
                handleCloseTicket,
                handleReopenTicket,
              }"
            >
              <MButton
                v-if="isTicketClosed"
                class="ml-2"
                @click="() => handleReopenTicket()"
              >
                {{ $t('reopen') }} {{ $tc(moduleName) }}
              </MButton>
              <MButton v-else class="ml-2" @click="() => handleCloseTicket()">
                {{ $t('close') }} {{ $tc(moduleName) }}
              </MButton>
            </template>
          </DetailView>
        </Transition>
      </template>
    </ListWithPreviewLayout>
    <!-- confirm modal for if ticket has secondarytickets and it gets closed -->
    <ConfirmCloseModal
      v-if="showConfirmCloseTicketModalFor > 0"
      :module-name="moduleName"
      @confirm="handleConfirmClose"
      @hide="showConfirmCloseTicketModalFor = null"
    >
      <template v-if="showChildMessasge" v-slot:message>
        {{
          $t('confirm_close_related', {
            resource: $tc(moduleName),
          })
        }}
      </template>
    </ConfirmCloseModal>
    <NoteRules ref="noteDrawer" :create-fn="handleNoteAdded" />
  </div>
</template>

<script>
import Bus from '@utils/emitter'
import Intersection from 'lodash/intersection'
import Pick from 'lodash/pick'
import { transformSearchCriteriaForServer } from '@data/search'
import { StatusComputed } from '@state/modules/status'
import { FormComputed } from '@state/modules/form'
import { PageMethods } from '@state/modules/page'
import CreateIncidentDrawer from './create-incident-drawer'
import {
  PreferenceComputed,
  PreferenceMethods,
} from '@state/modules/preference'
import { CustomRulesComputed } from '@state/modules/custom-rules'
import NoteRules from '@components/note-rules/note-rules'
import ListWithPreviewLayout from '@modules/ticket/components/list-with-preview/list-with-preview-layout'
import DetailView from '@modules/ticket/components/detail'
import ConfirmCloseModal from '@modules/ticket/components/confirm-close-modal'
import { searchTicketsApi, updateApi } from '@modules/ticket/ticket-api'
import TableRow from '@modules/ticket/components/list-table-row'
import CloneDeep from 'lodash/cloneDeep'
import { handleCheckRequiredNoteQualified } from '@/src/modules/ticket/helpers/check-required-note-field'

export default {
  name: 'RequestList',
  components: {
    ListWithPreviewLayout,
    DetailView,
    TableRow,
    ConfirmCloseModal,
    NoteRules,
    CreateIncidentDrawer,
  },
  props: {
    defaultChannel: {
      type: Object,
      default() {
        return {}
      },
    },
    isOpen: { type: Boolean, default: false },
  },
  data() {
    this.moduleName = this.$constants.REQUEST
    this.tableColumns = [
      {
        name: this.$t('subject'),
        key: 'subject',
        default: true,
        width: '210px',
        disabled: true,
        order: 1,
      },
    ]
    return {
      pendingChanges: null,
      currentView: 'split',
      showConfirmCloseTicketModalFor: null,
      internalColumns: null,
      showChildMessasge: false,
      allAvailableColumns: [],
      customFieldColumns: [],
      columns: [],
      orderedColumns: [],
      sortableColumnOptions: [],
      exportProcessing: false,
      wrapUpCallData: {},
    }
  },
  computed: {
    ...StatusComputed,
    ...Pick(FormComputed, ['requestForm']),
    ...CustomRulesComputed,
    ...Pick(PreferenceComputed, [
      'defaultListingColumns',
      'defaultLayout',
      'userPreferences',
    ]),
    closedStatusId() {
      return (this[`${this.moduleName}FindStatus`]('closed') || {}).id
    },
    defaultSelectedColumns() {
      return this.columns.map((c) => c.key)
    },
    renderCount() {
      return `${this.defaultChannel.requesterId}${this.wrapUpCallData.requestId}`
    },
  },
  created() {
    const handleAppWrapUpCallData = (change) => {
      this.wrapUpCallData = {
        ...this.wrapUpCallData,
        ...change,
      }
    }
    Bus.$on('app:update_wrap_up_call_data', handleAppWrapUpCallData)
    this.$once('hook:beforeDestroy', () => {
      Bus.$off('app:update_wrap_up_call_data', handleAppWrapUpCallData)
    })
  },
  methods: {
    ...PreferenceMethods,
    ...PageMethods,
    getRequests(searchCriterias, limit, offset, sortedColumn) {
      if (this.defaultChannel.unknown || !this.defaultChannel.requesterId) {
        return Promise.resolve({
          items: [],
          total: 0,
        })
      }
      const defaultQualifications = transformSearchCriteriaForServer(
        this.$constants.REQUEST,
        {
          operator: { value: 'in' },
          paramName: 'requesterId',
          value: this.defaultChannel.requesterId,
          valueType: 'long',
        }
      )
      return searchTicketsApi(
        this.moduleName,
        defaultQualifications || searchCriterias,
        limit,
        offset,
        sortedColumn
      ).then((data) => {
        if ((data.items || []).length) {
          this.$refs.listwithpreviewref.handleItemSelected(data.items[0])
        }
        return data
      })
    },
    navigateToItem(item) {
      this.$router.push(
        this.$modules.getModuleRoute('ticket', 'view', {
          params: {
            ticketType: this.$route.params.ticketType,
            id: item.id,
          },
        })
      )
    },
    handleNoteAdded(data) {
      return this.updateItem(
        {
          ...data,
          ...this.pendingChanges,
        },
        true
      ).then((data) => {
        this.pendingChanges = null
        this.$refs.noteDrawer.closeForm()
        this.resolveFn(data)
      })
    },
    updateItem(ticket, forceUpdate = false) {
      if (ticket.statusId === this.closedStatusId && !forceUpdate) {
        return new Promise((resolve, reject) => {
          this.showConfirmCloseTicketModalFor = ticket.id
          this.showChildMessasge = ticket.hasSecondaryTickets
          this.resolveFn = resolve
          this.rejectFn = reject
        })
      }
      const noteRequiredFields =
        this[`${this.moduleName}NoteRequiredFields`] || []

      const qualifiedRequiredNoteFields = CloneDeep(
        this[`${this.moduleName}QualifiedRequiredNoteFields`] || {}
      )
      if (
        Intersection(noteRequiredFields, Object.keys(ticket)).length &&
        forceUpdate === false &&
        handleCheckRequiredNoteQualified(
          ticket,
          noteRequiredFields,
          qualifiedRequiredNoteFields,
          this.moduleName
        )
      ) {
        this.$refs.noteDrawer.openForm()
        return new Promise((resolve, reject) => {
          this.pendingChanges = ticket
          this.resolveFn = resolve
          this.rejectFn = reject
        })
      }
      return updateApi(this.moduleName, ticket)
    },
    handleConfirmClose(event) {
      return this.updateItem(
        {
          ...event,
          id: this.showConfirmCloseTicketModalFor,
        },
        true
      ).then(this.resolveFn, this.rejectFn)
    },
  },
}
</script>
