<template>
  <TreePicker
    :key="pickerKey"
    :options="availableOptions"
    :value="parsedValue"
    searchable
    :is-larg-data-set="false"
    :placeholder="placeholder"
    :unassigned-value="unassignedValue"
    :multiple="multiple"
    v-bind="$attrs"
    @blur="$emit('blur')"
    @change="$emit('change', $event)"
    @selected="$emit('selected', $event)"
  >
    <template v-slot:trigger="slotData">
      <slot name="trigger" v-bind="slotData" :get-icon-name="iconName"></slot>
    </template>
    <template v-slot:item="{ item, select }">
      <div
        class="cursor-pointer pl-2 pr-1 py-1 rounded flex"
        :style="{ margin: '2px 0px', height: 'auto' }"
        @click="select"
      >
        <template v-if="displayIcon">
          <img
            v-if="item.iconSrc"
            :src="item.iconSrc"
            class="mr-2"
            style="max-height: 20px"
          />
          <CutomIcon
            v-else
            style="max-height: 20px"
            :name="iconName(item)"
            size="sm"
            class="mr-2"
          />
        </template>
        {{ item.name }}
      </div>
    </template>
    <!-- <template v-if="displayIcon" v-slot:trigger-prefix-text="{ item }">
      <div class="trigger-custom-icon">
        <CutomIcon
          style="max-height:15px"
          :name="iconName(item)"
          size="sm"
          class="mr-2"
        />
      </div>
    </template> -->
  </TreePicker>
</template>

<script>
import CutomIcon from '@components/custom-icon/custom-icon'
import SortBy from 'lodash/sortBy'
import {
  flattenRecursive,
  buildHierarchy,
  findValueObject,
} from '@data/recursive'
import { AssetTypeComputed } from '@state/modules/asset-type'
import { PreferenceComputed } from '@state/modules/preference'
// import _baseTreePicker from '../_base-tree-select.vue'
import { searchAssetTypesApi } from '@modules/asset-management/api/asset-type-api'
import TreePicker from '@components/tree-picker/tree-picker'
import { buildRelationalQualificationStructure } from '../../data/qualification'
import { getAssetTypeSystemName } from '@modules/asset/helpers/asset-type'

export default {
  name: 'FlotoAssetTypePicker',
  components: { TreePicker, CutomIcon },
  // extends: _baseTreePicker,
  model: { event: 'change' },
  props: {
    multiple: { type: Boolean, default: false },
    placeholder: {
      type: String,
      default() {
        return this.$tc('select')
      },
    },
    unassignedValue: { type: [String, Number], default: 0 },
    mandatory: { type: Boolean, default: false },
    systemName: { type: String, default: 'Hardware' },
    value: { type: [Number, Array, String], default: undefined },
    // availableAssetType will be array or string with systemName
    availableAssetType: { type: [String, Array], default: undefined },
    // eslint-disable-next-line
    displayIcon: { type: Boolean, default: true },
    availableFlatAssetType: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      archivedOptions: [],
    }
  },
  computed: {
    ...AssetTypeComputed,
    ...PreferenceComputed,
    availableTypesBasedOnPermissions() {
      const assetHardwareSystemName =
        getAssetTypeSystemName[this.$constants.ASSET_HARDWARE]

      const assetSoftwareSystemName =
        getAssetTypeSystemName[this.$constants.ASSET_SOFTWARE]

      const assetNonItSystemName =
        getAssetTypeSystemName[this.$constants.ASSET_NON_IT]

      const assetConsumableSystemName =
        getAssetTypeSystemName[this.$constants.ASSET_CONSUMABLE]

      let availableTypesBasedOnPermissions = []
      if (this.availableAssetType) {
        availableTypesBasedOnPermissions = this.availableAssetType
        return availableTypesBasedOnPermissions
      }
      if (this.myAllowedModules.indexOf('asset.hardware_software_asset') >= 0) {
        availableTypesBasedOnPermissions = [
          ...availableTypesBasedOnPermissions,
          assetHardwareSystemName,
          assetSoftwareSystemName,
        ]
      }
      if (this.myAllowedModules.indexOf('asset.non_it_asset') >= 0) {
        availableTypesBasedOnPermissions = [
          ...availableTypesBasedOnPermissions,
          assetNonItSystemName,
        ]
      }
      if (this.myAllowedModules.indexOf('asset.consumable_asset') >= 0) {
        availableTypesBasedOnPermissions = [
          ...availableTypesBasedOnPermissions,
          assetConsumableSystemName,
        ]
      }
      return availableTypesBasedOnPermissions
    },
    pickerKey() {
      if (!this.availableTypesBasedOnPermissions) {
        return '1'
      }
      return (
        Array.isArray(this.availableTypesBasedOnPermissions)
          ? this.availableTypesBasedOnPermissions
          : [this.availableTypesBasedOnPermissions]
      ).join()
    },
    parsedValue() {
      // parsed string value to integer
      return Array.isArray(this.value)
        ? (this.value || []).map((v) => (isNaN(v) ? v : +v))
        : isNaN(this.value)
        ? this.value
        : +this.value
    },
    options() {
      // @TODO when value is given and total is more than 1000 then we need to use search api to get items
      const options = this.assetTypes
      const flattenedOptions = flattenRecursive(options)
      const flatOptions = SortBy(
        flattenedOptions.filter((c) => !c.archived),
        'order'
      )
      const value = Array.isArray(this.parsedValue)
        ? this.parsedValue
        : [this.parsedValue]
      const selectedOptions = value.length
        ? flattenedOptions
            .filter((o) => value.indexOf(o.id) >= 0)
            .map((o) => ({ ...o, disabled: true }))
        : []
      let hierarchy = buildHierarchy(flatOptions)
      if (this.availableTypesBasedOnPermissions) {
        const availableTypes = Array.isArray(
          this.availableTypesBasedOnPermissions
        )
          ? this.availableTypesBasedOnPermissions
          : [this.availableTypesBasedOnPermissions]
        hierarchy = []
        availableTypes.forEach((t) => {
          let valueObject = findValueObject(
            this.assetTypes,
            t,
            'children',
            'systemName'
          )
          if (this.$attrs['excluded-children-asset-type']) {
            const excludedChildren = Array.isArray(
              this.$attrs['excluded-children-asset-type']
            )
              ? this.$attrs['excluded-children-asset-type']
              : [this.$attrs['excluded-children-asset-type']]
            valueObject = {
              ...valueObject,
              children: valueObject.children.filter(
                (c) => excludedChildren.indexOf(c.systemName) === -1
              ),
            }
          }
          if (
            this.availableFlatAssetType.length &&
            this.availableFlatAssetType.indexOf(valueObject.systemName) >= 0
          ) {
            valueObject = {
              ...valueObject,
              children: [],
            }
          }
          return hierarchy.push(valueObject)
        })
      }
      if (selectedOptions.length) {
        const availableIds = flatOptions.map((f) => f.id)
        hierarchy = hierarchy.concat(
          selectedOptions.filter((o) => availableIds.indexOf(o.id) === -1)
        )
      }
      return hierarchy.concat(this.archivedOptions)
    },
    availableOptions() {
      if (this.$attrs['additional-options']) {
        return (this.$attrs['additional-options'] || []).concat(this.options)
      }
      return this.options
    },
  },
  watch: {
    systemName(newValue) {
      if (!this.parsedValue && this.mandatory && newValue) {
        this.setValueBasedOnSystemNameAsDefault()
      }
    },
  },
  created() {
    if (this.parsedValue) {
      const v = Array.isArray(this.parsedValue)
        ? this.parsedValue
        : [this.parsedValue]
      const options = this.options
      const flattenedOptions = flattenRecursive(options)
      const allAvailableUserIds = flattenedOptions.map((u) => u.id)
      const missingAssetTypes = v.filter(
        (userId) => allAvailableUserIds.indexOf(userId) === -1 && !isNaN(userId)
      )
      if (missingAssetTypes.length) {
        this.fetchMissingAssetTypes(missingAssetTypes)
      }
    } else if (this.mandatory) {
      this.setValueBasedOnSystemNameAsDefault()
    }
  },
  methods: {
    setValueBasedOnSystemNameAsDefault() {
      const flattenedOptions = flattenRecursive(this.options)
      const hardwareAssetType = flattenedOptions.find(
        (o) => o.systemName === this.systemName
      )
      if (hardwareAssetType) {
        this.$emit('change', hardwareAssetType.id)
      }
    },
    iconName(item) {
      if (item.systemName) {
        return `${
          (item.systemName || '').split(' ').join('').toLowerCase() || ''
        }Icon`
      }
      return 'otherIcon'
    },
    fetchMissingAssetTypes(missingAssetTypes) {
      searchAssetTypesApi(
        this.$constants.ASSET,
        'assettype',
        {
          quals: [
            buildRelationalQualificationStructure(
              'id',
              'in',
              missingAssetTypes,
              'long',
              'db'
            ),
          ],
        },
        undefined,
        undefined,
        { archived: true }
      ).then((data) => {
        this.archivedOptions = Object.freeze(
          (data.items || []).map((c) => ({
            ...c,
            disabled: c.archived || c.disabled,
          }))
        )
      })
    },
  },
}
</script>

<style lang="less" scoped>
.trigger-custom-icon {
  display: inline-block;
  float: left;
  max-width: 25px;
  margin-top: 3px;
}
</style>
